<template>
  <div>
    <div class="ddiBanner" v-if="bannerList7014.length > 0">
      <div class="ddiBanner-inner vertical">
        <VueSlickCarousel v-if="bannerList7014" v-bind="this.topBannerSettings">
          <div v-for="(item, index) in bannerList7014" :key="`banner-7014-${index}`">
            <a :href="item.link" :target="item.linkTarget"><img :src="item.img"></a>
          </div>
        </VueSlickCarousel>
      </div>
      <div class="close" @click="closeDdiBanner"></div>
    </div>
    <header>
      <div class="header-wrap">
        <div class="header-inner"
          @mouseover="isHovering = true"
          @mouseout="isHovering = false"
          :class="{hoveringHeader: isHovering}"
        >
          <div class="headerTopArea">
            <div class="d-flex utillmenu topMenu">
              <div v-show="!isLoginStatus" class="icon-menu">
                <router-link to="/join">회원가입</router-link>
              </div>
              <div v-show="!isLoginStatus" class="icon-menu">
                <router-link to="/login" class="bar">로그인</router-link>
              </div>

              <div v-show="isLoginStatus" class="icon-menu hover">
                <template v-if="petList === null || petList.length === 0">
                <router-link to="/mypet/" class="selectPetTab">
                  <div class="d-flex align-center  cursor-pointer">
                    <div class="icon-btn icon-my">
                    </div>
                    <span>반려동물등록</span>
                    <div class="icon-plus"></div>
                  </div>
                </router-link>
                </template>
                <template v-else>
                <div>
                  <div class="d-flex align-center">
                    <div class="icon-btn icon-my ">
                      <image-alt v-if="mainPet.thumbnail" :src="mainPet.thumbnail" altType="profile"></image-alt>
                    </div>
                    <span>{{mainPet.name}}</span>
                    <div class="arrow-down"></div>
                  </div>
                </div>
                <div class="selectPetBox" @mouseleave="removeSelectPet()">
                  <div class="selectPet-inner">
                    <div class="selectPet-txt">
                      <div class="txt-top">
                        <span>대표펫</span>
                        <router-link to="/mypet">펫관리 바로가기</router-link>
                      </div>
                      <p>선택해 주신 아이 기준으로 맞춤 정보를 제공해 드릴게요.</p>
                    </div>
                    <ul class="myPetList">
                      <li v-for="(item,index) in petList" :key="`pet-${index}`" :class="[{ 'active' : item.isMain }, { 'selected' : petSelectId === item.idx }]" @click="petSelect(item)"  > 
                        <div class="icon-btn icon-my">
                          <image-alt v-if="item.thumbnail" :src="item.thumbnail" altType="profile"></image-alt>
                        </div>
                        <span>{{ item.name }}</span>
                      </li>
                    </ul>
                    <div :class="[ 'selectBtn', { 'active' : petSelectId }]" @click.prevent="setMainPet"><span>대표펫 변경</span></div>
                  </div>
                </div>
                </template>
              </div>
              <div v-show="isLoginStatus" class="icon-menu hover">
                <router-link to="/mypage/posts" class="d-flex align-center bar" >
                  <span>{{ getName }} 님</span>
                  <div class="arrow-down"></div>
                </router-link>
                <div class="submenu">
                  <div class="submenu-inner">
                    <router-link to="/mypet">펫관리</router-link>
                    <router-link to="/mypage/posts">게시글</router-link>
                    <router-link to="/mypage/activity">나의 활동</router-link>
                    <router-link to="/mypage/modify">회원정보 수정</router-link>
                    <router-link to="/mypage/password" v-if="getTarget === 'email'">비밀번호 변경</router-link>
                    <a href="#" @click="logout">로그아웃</a>
                  </div>
                </div>
              </div>
              <div class="icon-menu hover">
                <router-link to="/notice" class="d-flex align-center bar">
                  <span>고객센터</span>
                  <div class="arrow-down"></div>
                <!-- <div class="icon-btn icon-cs"></div> -->
                </router-link>
                <div class="submenu">
                  <div class="submenu-inner">
                    <router-link to="/notice">공지사항</router-link>
                    <a class="cursor-pointer" @click="checkLoginStatus('/inquiry')">1:1문의</a>
                    <router-link to="/faq">FAQ</router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="inner-layout flex-between">
              <div  class="d-flex">
                <div class="logo"><router-link to="/"><img src="@/assets/img/common/logo.png"></router-link></div>
                <div class="menu">
                  <div class="gnb1"><router-link to="/"><span>커뮤니티</span></router-link></div>
                  <router-link to="/event" class="gnb1"><span>이벤트</span></router-link>
                  <a href="https://ggolabshop.co.kr" target="_blank" class="gnb1"><span>스토어</span></a>
                </div>
              </div>
              
              <div>
                <div class="utillmenu d-flex">
                  <div class="search-box">
                    <input type="text" v-model="searchInput" @keypress.enter="search">
                    <button class="search-btn" @click="search"></button>
                  </div>
                  <div v-show="isLoginStatus" class="icon-menu" @click="openNotificationPopup" v-click-outside="closeNotificationPopup">
                    <div class="icon-btn icon-notice" :class="{ active : isShowPopup.notification }">알림</div>
                    <span class="notice-count"><i>{{ notificationCount }}</i></span>
                    <notification-popup v-if="isShowPopup.notification" @close="closeNotificationPopup" @set="setNotificationCount"></notification-popup>
                  </div>
                  <div class="write-btn-box icon-menu hover">
                    <div  @click="showSubMenu" :class="{ 'subMenu-on': isOpen }" class="btn-write" style="cursor: pointer" v-click-outside="hide">
                      <span>글쓰기</span>
                      <div class="arrow-down"></div>
                    </div>
                    <div class="writeMenu">
                      <div class="writeMenu-inner">
                        <div @click="goTipSave">
                          <a>
                            <img src="@/assets/svg/icon_tip.svg">
                            <div class="writeTxtBox">
                              <span class="writeTitle">꼬리TIP</span>
                              <span class="writeText">반려인들의 행복한 반려라이프&생활 꿀팁</span>
                            </div>
                          </a>
                        </div>
                        <div>
                          <router-link to="/ggomunity/new">
                            <img src="@/assets/svg/icon_community.svg">
                            <div class="writeTxtBox">
                              <span class="writeTitle">꼬뮤니티</span>
                              <span class="writeText">반려동물을 위한 건강/심리/양육 정보</span>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div><!-- writeMenu end -->
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
        
        <div class="header-bottom" :class="{ 'bottomMenu-hidden': !showBottomMenu }">
          <div class="bottomMenu">
            <router-link :to="home" :class="{ on : $route.fullPath === home }">홈</router-link>
            <router-link :to="tip" :class="{ on : $route.fullPath.startsWith(tip) }">꼬리TIP</router-link>
            <router-link :to="lab" :class="{ on : $route.fullPath.startsWith(lab) }">연구일지</router-link>
            <router-link :to="md" :class="{ on : $route.fullPath.startsWith(md) }">랜선집사</router-link>
            <router-link :to="ggomunity" :class="{ on : $route.fullPath.startsWith(ggomunity) }">꼬뮤니티</router-link>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import carouselMixin from "@/mixins/carouselMixin"
import bannerMixin from "@/mixins/bannerMixin"
import NotificationPopup from "@/components/common/NotificationPopup"

export default {
  mixins: [carouselMixin, bannerMixin],
  components: {NotificationPopup},
  data() {
    return {
      home: '/',
      tip: '/tip',
      lab: '/lab',
      md: '/md',
      ggomunity: '/ggomunity',

      searchInput: null,
      isShowPopup: {
        notification: false,
      },
      notificationCount: 0,
      bannerList7014: [], // 상단 띠 배너(PC)
      ddiBannerHeight:0,
      showBottomMenu: true,
      lastScrollPosition: 0,
      isHovering: false,
      isOpen: false,
      isSelected: false,
      petList: null,
      petSelectId: null,

      mainPet: {},
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'session/isLogin',
      getAuthToken: 'session/getAuthToken',
      getId: 'session/getId',
      getEmail: 'session/getEmail',
      getName: 'session/getName',
      getUrl: 'session/getUrl',
      getTarget: 'session/getTarget',
      getPhone: 'session/getPhone',
      getMainPetName: 'common/getMainPet'
    }),
    isLoginStatus() {
      return this.isLogin;
    },
  },
  watch: {
    $route() {
      this.setNotificationCount()
      this.getWordFilter() // 단어 필터링 목록
    }
  },
  beforeDestroy(){
    this.$eventbus.$off('initNotificationCount')
  },
  created() {

  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)

    this.setNotificationCount()
    this.$eventbus.$on('initNotificationCount', () => {
      this.setNotzificationCount()
    })
    this.getWordFilter() // 단어 필터링 목록
    this.setBanner7014() // 상단 띠 배너(PC)

    if (this.isLoginStatus) {
      this.getMainPet()
      this.getPetList()
    }
  },
  beforeRemove() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods:{
    ...mapActions({
      setWordFilter: 'common/setWordFilter',
      clearSession: 'session/clearSession',
      setMainPetId: 'common/setMainPetId',
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getMainPet() {
      const { result, data } = await this.$api.getMainPet()
      if (result === 'success') this.mainPet = data
    },
    async getPetList() {
      const { result, list } = await this.$api.getPetsList()
      if (result === 'success') {
        this.petList = list
        if(this.petList.length > 0) {
          const mainPet = list.filter(list => {
            return list.isMain === 1
          })
          this.setMainPetId(mainPet[0].idx)
        }
      }
    },
    petSelect(item) {
      if (!item.isMain) {
        this.petSelectId = this.petSelectId === item.idx ? null : item.idx
      }
    },
    async setMainPet() {
      const { result } = await this.$api.setMainPet({petIdx : this.petSelectId})
      if(result === 'success'){
        $toast('대표펫 변경이 완료됐습니다.')
        await this.getPetList()
        await this.getMainPet()
      }
    },
    search() {
      if (this.searchInput) {
        this.$router.push(`/search?input=${this.searchInput}`)
        this.searchInput = null
      }
    },
    async setNotificationCount() {
      if (!this.isLogin) return false
      const { result, count } = await this.$api.getNotificationCount()
      if (result === 'success') this.notificationCount = count
    },
    async getWordFilter() {
      const { result, data } = await this.$api.getWordFilter()
      if (result === 'success') this.setWordFilter({ wordFilter: data })
    },
    goTipSave() {
      if (this.$isIE()) {
        this.$router.push('/ie');
      }
      else {
        this.checkLoginStatus('/tip/new')
      }
    },
    openNotificationPopup() {
      this.isShowPopup.notification = true
    },
    closeNotificationPopup() {
      this.isShowPopup.notification = false
    },
    logout() {
      this.clearSession()
      localStorage.removeItem('auth')
      localStorage.removeItem('id')
      localStorage.removeItem('email')
      localStorage.removeItem('name')
      localStorage.removeItem('url')
      localStorage.removeItem('target')
      localStorage.removeItem('phone')
      location.href = "/"
    },
    checkLoginStatus(path) {
      if (!this.isLogin) {
        this.loginPopup(path)
      }
      else {
        this.$router.push(path)
      }
    },
    loginPopup(path) {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('login.need'),
        okay: 'OK',
        okayCallback: () => {
          this.setMsgPopup()
          localStorage.setItem('loginBeforePage', path)
          this.$router.push('/login')
        }
      })
    },
    async setBanner7014() {
      const handleScroll = () => {
        const ddiBanner = document.querySelector('.ddiBanner')
        const header = document.querySelector('.header-wrap')
        if (header) {
          let ddiBannerHeight = 0
          if (ddiBanner) ddiBannerHeight = ddiBanner.clientHeight
          const currentYOffset = window.scrollY
          if(currentYOffset <= ddiBannerHeight){
            header.classList.remove('fix')
          } else {
            header.classList.add('fix')
          }
        }
      }
      this.bannerList7014 = this.$cookie.get('ggolab-banner-7014') ? [] : await this.getBanner(7014)
      if (this.bannerList7014) {
        setTimeout(() => {
          handleScroll()
          window.addEventListener('scroll', handleScroll)
        }, 100)
        setTimeout(() => {
          this.setHeightForVerticalSlide()
        }, 300)
      }
    },
    closeDdiBanner(){
      this.bannerList7014 = []
      const expires = '1d'
      const domain = location.host.split(':')[0]
      this.$cookie.set('ggolab-banner-7014', true, { domain, expires })
    },
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 46) {
        return
      }
      this.showBottomMenu = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    },
    showSubMenu() {
      this.isOpen = !this.isOpen
    },
    hide() {
      this.isOpen = false
    },
    removeSelectPet() {
      this.petSelectId = null
    }
  }
}
</script>
