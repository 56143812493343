<template>
  <div>
    <div class="popupBox header-notice-list">
      <div class="popupBox-title btween">
        <div>알림</div>
        <div v-if="list" class="btn-wrap">
          <span class="cursor-pointer" @click="readAll">전체읽음</span>
          <span class="link-bar"></span>
          <span class="cursor-pointer" @click="confirmRemoveAllPopup">전체 삭제</span>
        </div>
      </div>

      <div class="tab-btn-box fix between-bar">
        <div class="tab-btn w-50p" :class="{ on : filter === 1 }" @click="changeFilter(1)">활동알림 {{ noticeCount }}</div>
        <div class="tab-btn w-50p" :class="{ on : filter === 2 }" @click="changeFilter(2)">새로운 소식 {{ newsCount }}</div>
      </div>

      <div class="popupBox-body full inner-scroll">

        <div v-if="!list || list.length === 0" class="noDataMessage2">알림 내역이 없습니다.</div>

        <div v-if="filter === 1" v-for="item in list" :key="`notice-${item.id}`" class="my-notice-list-item cursor-pointer" :class="{ active : item.readYN === 0 }" @click="read(item)">
          <div class="icon-notice-list">
            <div :class="setTypeClass(item.type)"></div>
            <div class="icon-text">{{ item.type === 0 ? '댓글' : '답글' }}</div>
          </div>
          <div class="category">{{ item.menuName }}/{{ item.categoryName }}</div>
          <div class="subject">{{ item.title }}</div>
          <div class="user-info">By {{ item.nickName }}  I  {{ $getDateFormat(item.createDate, 'Y.m.d H:i') }}</div>
          <div class="btn-delete" @click.stop="confirmRemovePopup(item.id)"></div>
        </div>

        <div v-if="filter === 2" v-for="item in list" :key="`notice-${item.id}`" class="my-notice-list-item cursor-pointer" :class="{ active : item.readYN === 0 }" @click="read(item)">
          <div class="icon-notice-list">
            <div :class="setTypeClass(item.type)"></div>
            <div class="icon-text">{{ item.menuName }}</div>
          </div>
          <div class="category">{{ item.menuName }}</div>
          <div class="subject">{{ item.title }}</div>
          <div class="user-info">{{ $getDateFormat(item.createDate, 'Y.m.d H:i') }}</div>
          <div class="btn-delete" @click.stop="confirmRemovePopup(item.id)"></div>
        </div>

      </div>

      <div class="popupBox-bottom full cursor-pointer" @click="goList">
        <div class="to-myNotice">내 알림 전체보기</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"
export default {
  name: "notificationPopup",
  data() {
    return {
      filter: 1, // 1: 활동알림(notice), 2: 새로운소식(news)
      noticeCount: 0,
      newsCount: 0,
      list: null,
    }
  },
  created() {
    this.setList()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    changeFilter(filter) {
      this.filter = filter
      this.setList()
    },
    setTypeClass(type) {
      if (this.filter === 1 && type === 0) return 'icon-img icon-comment'
      if (this.filter === 1 && type === 1) return 'icon-img icon-reply'
      if (this.filter === 2 && type === 0) return 'icon-img icon-event'
      if (this.filter === 2 && type === 1) return 'icon-img icon-notice2'
    },
    async setList() {
      const { result, list, noticeCount, newsCount } = await this.$api.getNotificationList({
        target: this.filter,
        page: 1,
        pageSize: 40,
      })
      if (result === 'success') {
        this.noticeCount = noticeCount
        this.newsCount = newsCount
        this.list = list
      }
    },
    async read(item) {
      if (!item.id) return false
      const { result } = await this.$api.readNotification({
        target: this.filter,
        id: item.id,
      })
      if (result === 'success') this.setCount()

      if (this.filter === 2 && item.url) {
        window.open(item.url)
        this.list = this.list.map(l => {
          if (l.id == item.id) l.readYN = 1
          return l
        })
      }
      else {
        await this.$router.push(`/${item.menu}/${item.boIdx}`)
        this.close()
      }
    },
    async readAll() {
      const { result } = await this.$api.readNotification({
        target: this.filter,
        id: 0,
      })
      if (result === 'success') {
        this.list = this.list.map(l => {
          if (l.readYN === 0) l.readYN = 1
          return l
        })
        this.setCount('all')
      }
    },
    confirmRemovePopup(id) {
      if (!id) return false
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          const { result } = await this.$api.removeNotification({
            target: this.filter,
            id: id,
          })
          if (result === 'success') {
            this.list = this.list.filter(l => l.id !== id)
            this.setCount()
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    confirmRemoveAllPopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          const { result } = await this.$api.removeNotification({
            target: this.filter,
            id: 0,
          })
          if (result === 'success') {
            this.list = null
            this.setCount('all')
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    setCount(type) {
      if (type === 'all') {
        if (this.filter === 1) this.noticeCount = 0
        if (this.filter === 2) this.newsCount = 0
      }
      else {
        if (this.filter === 1 && this.noticeCount > 0) this.noticeCount--
        if (this.filter === 2 && this.newsCount > 0) this.newsCount--
      }
      this.$emit('set')
    },
    close() {
      this.$emit('close', '')
    },
    goList() {
      this.close()
      this.$router.push('/notification')
    }
  }
}
</script>
